import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UserService } from '../../../../core/user/user.service';
import { VersiongService } from '../../../../core/versionning/versionning.service';
import { Version } from '../../../../core/versionning/versionning.types';

@Component({
    selector: 'dense-layout',
    templateUrl: './dense.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DenseLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
    isScreenSmall: boolean;
    navigation: Navigation;
    navigationAppearance: 'default' | 'dense' = 'dense';
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    ariane: any;
    isAdmin: boolean = false;
    version: Version = null;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _userService: UserService,
        private cdRef: ChangeDetectorRef,
        private _versionService: VersiongService
    ) {
    }
    ngAfterViewInit(): void {
        this._navigationService.ariane$.pipe(takeUntil(this._unsubscribeAll)).subscribe(ari => { this.ariane = ari; this.cdRef.detectChanges(); })
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe(
            u => {
                this.isAdmin = u.accessLevel == 4
            }
        )

        this._versionService.version$.pipe(takeUntil(this._unsubscribeAll)).subscribe(
            v => {
                this.version = v;
                //this.titleService.setTitle((v.appEnv.toUpperCase() == "PROD" ? "" : v.appEnv.toUpperCase() + " - ") + "LENS - Corporatings")
            }
        )


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    /**
     * Toggle the navigation appearance
     */
    toggleNavigationAppearance(): void {
        this.navigationAppearance = (this.navigationAppearance === 'default' ? 'dense' : 'default');
    }

    /**
     * Go to Admin
     */
    goToAdmin(event : MouseEvent) {
        if(event.ctrlKey) {
            window.open("/admin", '_blank')
        } else
            this._router.navigateByUrl("/admin")
    }

    /**
     * Open hubspot
     */
    goToHubspot() {
        window.open('https://meetings.hubspot.com/alexandre-prat-fourcade/clone', '_blank')
    }

    goContactByMail() {
        window.open('mailto:contact@corporatings.com')

    }
}
