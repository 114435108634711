<div class="flex flex-col items-center w-full px-6 max-w-full">

    <div class="flex flex-col justify-start items-start w-full px-6 mt-8 mb-4 space-y-4 max-w-full">
        <div class="flex py-6 px-6 space-x-4 rounded-2xl shadow-lg justify-center items-center w-full bg-background-secondary">
            <div class="flex flex-col mx-6 w-full justify-center">
                <mat-form-field class="roundedfield mdfield flex flex-col" appearance="fill">
                    <input type="text"
                           placeholder="Search"
                           matInput
                           [formControl]="searchForm"
                           [matAutocomplete]="auto"
                           (keyup.enter)="nav()">
                    <mat-icon matSuffix svgIcon="heroicons_outline:search" class="h-5 w-5 min-w-5 min-h-5"></mat-icon>
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredCompanies | async" [value]="option.name">
                            <div class="flex flex-row w-full h-14" [title]="companyToString(option)" (click)="navigateToIssuerFromTable($event, option.issuerId)">
                                <img [src]="companyLogo(option) | protectedLink | async"
                                     class="w-14 h-14 mr-2"
                                     alt="Logo image">
                                <p class="font-semibold">{{option.name}}</p>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <!--<companySearch #search (keypress)="onKey($event)" (notify)="filterByQuery($event)" (notif)="navigateTo($event)"></companySearch>-->
            <!--<search> </search> -->
            <div class="flex flex-col justify-center h-full">
                <button (click)="nav()" class="btn-primary-md">
                    Search
                    <fa-icon class="icon-size-3 ml-2" [icon]="['fas', 'magnifying-glass']"></fa-icon>
                </button>
            </div>
        </div>

        <div *ngIf="!showSearchResults" class="flex flex-col space-y-2.5 w-full max-w-full">
            <div class="flex flex-col w-full max-w-full">
                <h2 class="text-xl font-extrabold">Recent</h2>
                <div class="flex flex-row overflow-hidden max-w-full flex-wrap py-1 px-1" style="height: 10.50rem; max-height: 10.50rem" *ngIf="recentlyViewedCompanies?.length">
                    <div class="bg-card cursor-pointer shadow rounded-md py-4 px-6 w-72 max-w-72 min-w-72 mr-4 h-40 max-h-40 min-h-40 mb-1" *ngFor="let recent of recentlyViewedCompanies"
                         (click)="navigateToIssuerFromTable($event, recent.id)">
                        <div [matTooltip]="recent.name" class="flex flex-col p-2 h-full justify-center">
                            <div class="flex flex-row w-full justify-center">
                                <img [src]="companyLogo(recent) | protectedLink | async" class="w-14 h-14"
                                     alt="Logo image">
                            </div>
                            <div class="text-center px-2 mt-2 max-w-full multiline-ellipsis-2 font-semibold">{{recent.name}}</div>
                            <div class="flex flex-1"></div>
                        </div>
                        <!--<span [matTooltip]="recent.name" class="avatar"> {{recent.name.slice(0,2).toUpperCase()}} </span>-->
                    </div>
                </div>

                <div class="flex flex-row" *ngIf="!recentlyViewedCompanies?.length">
                    No recent visit recorded
                </div>
            </div>

            <div class="flex flex-col w-full max-w-full">
                <h2 class="text-xl font-extrabold">Favorites</h2>
                <div class="flex flex-row overflow-x-auto overflow-y-visible max-w-full py-3 px-1" *ngIf="fav_compagnies?.length">
                    <div class="bg-card cursor-pointer shadow rounded-md py-4 px-6 w-72 max-w-72 min-w-72 mr-4 h-40 max-h-40 min-h-40" *ngFor="let fav of fav_compagnies"
                         (click)="navigateToIssuerFromTable($event, fav.id)">
                        <div [matTooltip]="fav.name" class="flex flex-col p-2 h-full justify-center">
                            <div class="flex flex-row w-full justify-center">
                                <img [src]="companyLogo(fav) | protectedLink | async" class="w-14 h-14"
                                     alt="Logo image">
                            </div>
                            <div class="text-center px-2 mt-2 max-w-full multiline-ellipsis-2 font-semibold">{{fav.name}}</div>
                            <div class="flex flex-1"></div>
                        </div>
                        <!--<span [matTooltip]="recent.name" class="avatar"> {{recent.name.slice(0,2).toUpperCase()}} </span>-->
                    </div>
                </div>

                <div class="flex flex-row" *ngIf="!recentlyViewedCompanies?.length">
                    No recent visit recorded
                </div>
            </div>

            <div class="flex flex-col w-full max-w-full">
                <h2 class="text-xl font-extrabold">Most popular</h2>
                <div class="flex flex-row overflow-hidden max-w-full flex-wrap py-1 px-1" style="height: 10.50rem; max-height: 10.50rem" *ngIf="mostViewedCompanies?.length">
                    <div class="bg-card cursor-pointer shadow rounded-md py-4 px-6 w-72 max-w-72 min-w-72 mr-4 h-40 max-h-40 min-h-40 mb-1" *ngFor="let most of mostViewedCompanies" (click)="navigateToIssuerFromTable($event, most.id)">
                        <!--<div [matTooltip]="most.iss_Name" class="avatar"> {{most.iss_Name.slice(0,2).toUpperCase()}} </div>-->
                        <div [matTooltip]="most.iss_Name" class="flex flex-col p-2 h-full justify-center">
                            <div class="flex flex-row w-full justify-center">
                                <img [src]="companyLogo(most) | protectedLink | async"
                                     class="w-14 h-14"
                                     alt="Logo image">
                            </div>
                            <div class="text-center px-2 mt-2 max-w-full multiline-ellipsis-2 font-semibold">{{most.iss_Name}}</div>
                            <div class="flex flex-1"></div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row" *ngIf="!mostViewedCompanies?.length">
                    No visit recorded
                </div>
            </div>
        </div>

        <div *ngIf="showSearchResults" class="w-200">
            <table class="w-full" mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="x1">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Company name</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-base truncate text-left cursor-pointer" style="max-width: 200px"
                             (mouseover)="changeStyleTruncate($event)" (mouseout)="changeStyleTruncate($event)"
                             (click)="navigateToIssuerFromTable($event, element.issuerId)"
                             [title]="element.name">
                            {{element.name}}
                        </div>
                        <!--<span class="cursor-pointer" (click)="navigateToIssuerFromTable(element.issuerId)">
                            {{element.name}}
                        </span>-->
                    </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="x2">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-4">Actions</th>
                    <td mat-cell *matCellDef="let element" class="w-4">
                        <button>
                            <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:bookmark'"></mat-icon>
                        </button>
                        <button>
                            <mat-icon class="icon-size-5" [svgIcon]="'checklist'"></mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[5, 10]"
                           showFirstLastButtons
                           aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>

    </div>

    <!-- <div class="flex flex-col w-60 shadow rounded-2xl mt-8" style="background-color: #F6FDFD">
        <div class="flex flex-row text-2xl font-bold p-4 rounded-t-2xl">
            <div class="flex flex-1"></div>
            <div class="flex flex-row">
                <mat-icon matPrefix class="h-4 w-4" [svgIcon]="'heroicons_outline:bookmark'"></mat-icon>
                <div class="ml-4">Favorites</div>
            </div>
            <div class="flex flex-1"></div>
        </div>
        <div class="h-0.5 bg-gray-300"></div>
        <div fuseScrollbar [fuseScrollbarOptions]="{wheelPropagation: false, suppressScrollX: true}" class="flex flex-col max-h-4/5">
            <a *ngFor="let fc of fav_compagnies_showed; index as i" (click)="navigateTo(fc.id)" class="cursor-pointer w-full" style="height: min-content">
                <div class=" flex flex-row p-4 transition ease-in-out delay-150 hover:bg-gray-100 duration-300" [class.border-t-2]="i!=0" [class.border-gray-300]="i!=0" style="height: 100%">
                    <div class="flex flex-col w-6 justify-center">
                        <img [src]="companyLogo(fc) | protectedLink | async"
                                class="w-6 h-6"
                                alt="Logo image">
                    </div>
                    <div class="flex flex-1"></div>
                    <div class="flex flex-col justify-center text-center">
                        {{fc.name}}
                    </div>
                    <div class="flex flex-1"></div>
                </div>
            </a>
        </div>
        <div class="h-0.5 bg-gray-300" *ngIf="fav_compagnies.length>=25"></div>
        <div class="flex flex-row justify-center">
            <mat-paginator class="mb-2" *ngIf="fav_compagnies.length>=25" #favPaginator="matPaginator" [pageSize]="25" [pageSizeOptions]="[25, 50, 100]" [length]="fav_compagnies.length" (page)="favChangePage($event)"></mat-paginator>
        </div>
        <div class="flex flex-1"></div>
        <div class="flex flex-row justify-center">
            <button class="text-base flex flex-row mx-2 my-4" (click)="onAddFavoriteButton()" corpo-action-button>
                <div class="mx-2">Add a favorite</div>
            </button>
        </div>
    </div> -->
</div>
