<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-corpo-darkgreen print:hidden"
                          [appearance]="navigationAppearance"
                          [mode]="isScreenSmall ? 'over' : 'side'"
                          [name]="'mainNavigation'"
                          [navigation]="navigation.default"
                          [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-18">
            <img style="max-width:max(0px, calc((100% - 150px)*1000)); overflow: hidden;"
                 class="w-40"
                 src="assets/images/logo/logo-long.png"
                 alt="Logo image">
            <img style="max-width:max(0px, calc((90px - 100%)*1000)); overflow: hidden;"
                 class="w-12"
                 src="assets/images/logo/logo.png"
                 alt="Logo image">
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-12 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-corpo-darkgreen dark:bg-transparent print:hidden">
        <div class="flex items-center pr-2 space-x-2">
            <!-- Navigation toggle button -->
            <!--button
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button-->
            <!-- Navigation appearance toggle button -->
            <!--button
                class="hidden md:inline-flex"
                mat-icon-button
                (click)="toggleNavigationAppearance()">
                <mat-icon [svgIcon]="'heroicons_outline:switch-horizontal'"></mat-icon>
            </button-->
            <div class="w-2"></div>
            <ng-container *ngFor="let item of ariane;">
                <a class="text-background-primary" [href]="item.link">{{item.title}} <ng-container *ngIf="!item.last">></ng-container></a>
            </ng-container>
        </div>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <button mat-icon-button *ngIf="isAdmin" (click)="goToAdmin($event)">
                <mat-icon class="rounded-full" style="color: white;" svgIcon="mat_outline:home_repair_service"></mat-icon>
            </button>
            <!--fuse-fullscreen class="hidden md:block"></fuse-fullscreen-->
            <notifications *ngIf="isAdmin"></notifications>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div class="relative flex items-center justify-around w-full h-18 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden text-gray-400">
        <div class="flex  hover:text-gray-700 hover:cursor-wait cursor-pointer">
            <a href="https://support.corporatings.com/release_notes/">
                Version {{version?.buildDate}}
            </a>
        </div>
        <div class="w-1/2 flex space-x-10 justify-center items-center h-full font-semibold">
            <div class="flex space-x-2 hover:text-gray-700 hover:cursor-wait cursor-pointer" (click)="goToHubspot()">
                <p>Book a call</p>
                <fa-icon [icon]="['fas', 'calendar-days']" class="icon-size-4 flex items-center"></fa-icon>
            </div>

            <div class="flex space-x-2 hover:text-gray-700 hover:cursor-wait cursor-pointer">
                <a href="mailto:contact&#64;corporatings.com">contact&#64;corporatings.com</a>
                <fa-icon [icon]="['fas', 'envelope']" class="icon-size-4 flex items-center"></fa-icon>
            </div>

        </div>
        <span class="text-secondary">Corporatings &copy; {{currentYear}}</span>

    </div>

</div>

